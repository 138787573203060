<template>
    <div>
        <v-card>
            <v-toolbar dense>
                <v-toolbar-title>
                    <v-row style="margin-top: 10px">
                        <v-col cols="12" style="display: inline-flex;">
                            <v-chip
                                style="margin: 0px"
                                small
                                :color="'info'"
                            >
                                {{ "Ingreso QR" }} 
                            </v-chip>
                        </v-col>
                    </v-row>
                </v-toolbar-title>
            </v-toolbar>
        </v-card>
        <v-card dense>
            <v-row justify="center" style="margin:auto">
                <v-col
                    align="center"
                    cols="12"
                    lg="12"
                    md="12"
                >   
                    <s-scanner-qr
                        :config="configScann"
                        return-object 
                        @onValue="onValue($event)"
                        :readonly="false"
                        :autofocus="false"
                        :clearInput="clearInput"
                        >
                    </s-scanner-qr>
                </v-col>
            </v-row>
            <v-row justify="center" style="margin:auto">
                <v-col  cols="6" lg="6" md="6">
                    <s-select-definition
                        label = "Tipo de Ingreso"
                        :def="1456"
                        v-model="itemHead.TypeEntry"
                    >

                    </s-select-definition>
                </v-col>
            </v-row>
            
            <v-row>
				<v-col lg="12" class="pb-1">
					<s-crud
						:filter="filter"
						:config="config"
						searchInput
						title="Listado de pallets Ingresados"
						no-full
						height="auto"
						dense
						ref="gridIncomeArea"
					>
                    <!-- @rowSelected="rowSelected($event)" -->
						<!-- Filtros -->
						<template v-slot:filter>
							<v-container>
								<v-row justify="center" class="s-col-form">
									
									<v-col justify="center" lg="3" cols="12"
										><s-date
											label="Fecha Inicio"
											v-model="filter.DateBegin"
										></s-date>
									</v-col>
									<v-col lg="3" cols="12"
										><s-date
											label="Fecha Fin"
											v-model="filter.DateEnd"
										></s-date>
									</v-col>
								</v-row>
								
								<v-row class="s-col-form">
									
								</v-row>
							</v-container>
						</template>

						<template v-slot:details="{ row }">
							<v-btn
								x-small
								fab
								style="margin: 6px 6px 6px 6px; background-color: #dd3b62; color: white;"
								@click="deleteParihuela(row)">
								<v-icon
                                    style="font-size: 16px !important">
                                    fas fa-times
                                </v-icon>
							</v-btn>
						</template>
                        <template v-slot:PacID="{ row }">
                            <input type="hidden" v-model="row.PacID"/>
                        </template>
						
					</s-crud>
				</v-col>
			</v-row>
                  
        </v-card>
    
       
    
    </div>
</template>
<script>
    import sScannerQr from '../../../../components/Utils/sScannerQr.vue';

    import _sFrzChamberIncomePallet from "@/services/FrozenProduction/FrzIncomePalletChamber.js";
   


    export default{
        components: { sScannerQr},
        data:() => ({
           
            clearInput:false,
            configScann: {}, 

            filter: {
                DateBegin: null,
                DateEnd: null,                    
            },
            
            config: {
				model: {
					PacID: "ID",
                    DateBegin: "date",
                    DateEnd: "date",
                    SecStatus: "SecStatus",
                    details:"",
                    repackaging:""
				},
				service: _sFrzChamberIncomePallet,
				headers: [								
					{ text: "ID", value: "PacID", hide: true },
                    { text: "N° Pallet", value: "PalletGroupID" },
                    { text: "Fecha", value: "DateIncome"},
                    { text: "Hora", value: "HourIncome" },
                    { text: "Cliente", value: "CumNombre" },
                    { text: "Marca", value: "CbdBrans" },
                    { text: "Variedad", value: "VrtDescription" },
                    { text: "Tipo de Corte", value: "TypeCutName" },
                    { text: "Presentacion", value: "TypePresentationName" },
                    { text: "N° Cajas", value: "NumberBoxes" },
					{ text: "Peso Neto", value: "WeightNetBoxes" },	
                    { text: "E. Final", value: "StateFinalString" },
                    { text: "FRUTA", value: "TypeCultiveName" },	 
                    // { text: "ID Proveedor", value: "RcfIDs" },
                    { text: "ID Trazabilidad", value: "TraceabilityID"},
					{ text: "T. Cong.", value: "CecDescription" },
                   
                    // { text: "Estado", value: "TypeEntryName" },	
                    // { text: "T. Produccion", value: "TypeProductiontString" },	
                    // { text: "Variante", value: "TypeVariantString" },	 
                                
                    { text: "Observación", value: "Observation" },					
                    { text: "Eliminar", value: "details" },		
				],
			},
            itemHead:{

            },           
           
        }),
        
        
        methods:{
            
            
            onValue(val)
            {
                
                if (val == "Sin datos" || isNaN(val)) {
					this.$fun.alert("El QR Leido no contiene datos", "warning");
				} else {

                  
                    let item = {};
                
                    // item.PapID = val;
                    item.PalletGroupID = val;
                    item.TypeEntry  = this.itemHead.TypeEntry;
                    item.UsrCreateID = this.$fun.getUserID();
                    item.UsrUpdateID = this.$fun.getUserID();
                    
                  
                    _sFrzChamberIncomePallet.save(item, this.$fun.getUserID())
                        .then( r => {
                            if(r.status == 200)
                            {                                
                                this.$fun.alert("Pallet ingresado correctamente", "success");
                                this.$refs.gridIncomeArea.refresh();
                            }
                        });
                    }

                
            },
            deleteParihuela(item){
                let val = {
                    PacID : item.PacID,
                    UsrUpdateID : this.$fun.getUserID()
                }

                // console.log('data',val);
                // return
                this.$fun.alert("Esta seguro de eliminar ?", "question")
                .then(res => {
                    if (res.value) {
                        _sFrzChamberIncomePallet
                        .update(val, this.$fun.getUserID())
                        .then(r => {
                            if (r.status == 200) {
                                this.$fun.alert("Pallet eliminado correctamente", "success");
                                this.$refs.gridIncomeArea.refresh();
                            }
                        });
                    }
                });


            },
           
            
        },
        created()
        {
            
        }
    }
</script>